var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"giga-fields"},[_c('div',{staticClass:"giga-input_field col-lg-6",class:[
      _vm.tabFiche.precarityControl.precarity.$anyDirty &&
      _vm.tabFiche.precarityControl.precarity.$invalid
        ? 'field-hasError'
        : ''
    ]},[_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("precarity")))]),_c('div',{staticClass:"input-field_area"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tabFiche.precarityControl.precarity.$model),expression:"tabFiche.precarityControl.precarity.$model"}],staticClass:"small-input",attrs:{"id":"precarity","disabled":_vm.isVIEWER},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tabFiche.precarityControl.precarity, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getPrecarityByPeriodAndDepartment(_vm.tabFiche)}]}},_vm._l((_vm.precarityCases),function(precarity){return _c('option',{key:precarity.value,domProps:{"value":precarity.value}},[_vm._v(" "+_vm._s(precarity.name)+" ")])}),0),(
          _vm.tabFiche.precarityControl.precarity.$anyDirty &&
            _vm.tabFiche.precarityControl.precarity.$invalid
        )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.tabFiche.precarityControl.precarity.required)?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]),(_vm.tabFiche.precarityControl.precarity.$model === 'CASE_1')?_c('div',{staticClass:"giga-input_field checkbox-field_area col-lg-12"},[_c('div',{staticClass:"input-field_area"},[_c('div',{staticClass:"giga-cutsom_checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tabFiche.precarityControl.bigPrecarity.$model),expression:"tabFiche.precarityControl.bigPrecarity.$model"}],attrs:{"type":"checkbox","name":"bigPrecarity","id":"bigPrecarity","disabled":_vm.isVIEWER},domProps:{"checked":Array.isArray(_vm.tabFiche.precarityControl.bigPrecarity.$model)?_vm._i(_vm.tabFiche.precarityControl.bigPrecarity.$model,null)>-1:(_vm.tabFiche.precarityControl.bigPrecarity.$model)},on:{"change":function($event){var $$a=_vm.tabFiche.precarityControl.bigPrecarity.$model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.tabFiche.precarityControl.bigPrecarity, "$model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.tabFiche.precarityControl.bigPrecarity, "$model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.tabFiche.precarityControl.bigPrecarity, "$model", $$c)}}}}),_c('label',{attrs:{"for":"checkboxName"}})]),(
          _vm.tabFiche.precarityControl.bigPrecarity.$anyDirty &&
            _vm.tabFiche.precarityControl.bigPrecarity.$invalid
        )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.tabFiche.precarityControl.bigPrecarity.required)?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()]),_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("Ménage en grande précarité")))])]):_vm._e(),(
      _vm.tabFiche.precarityControl.precarity.$model !== 'CASE_1' &&
        _vm.tabFiche.precarityControl.precarity.$model !== 'NO'
    )?_c('div',{staticClass:"giga-input_field col-lg-12",class:[
      _vm.tabFiche.precarityControl.coupleNumber.$anyDirty &&
      _vm.tabFiche.precarityControl.coupleNumber.$invalid
        ? 'field-hasError'
        : ''
    ]},[_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("Nombre total de ménages")))]),_c('div',{staticClass:"input-field_area",staticStyle:{"width":"50%"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tabFiche.precarityControl.coupleNumber.$model),expression:"tabFiche.precarityControl.coupleNumber.$model"}],staticClass:"small-input",attrs:{"type":"number","disabled":_vm.isVIEWER,"id":"coupleNumber"},domProps:{"value":(_vm.tabFiche.precarityControl.coupleNumber.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tabFiche.precarityControl.coupleNumber, "$model", $event.target.value)}}}),(
          _vm.tabFiche.precarityControl.coupleNumber.$anyDirty &&
            _vm.tabFiche.precarityControl.coupleNumber.$invalid
        )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.tabFiche.precarityControl.coupleNumber.required)?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.tabFiche.precarityControl.precarity.$model === 'CASE_2_BIS')?_c('div',{staticClass:"giga-input_field col-lg-6",class:[
      _vm.tabFiche.precarityControl.coupleNumberInSocialHousing.$anyDirty &&
      _vm.tabFiche.precarityControl.coupleNumberInSocialHousing.$invalid
        ? 'field-hasError'
        : ''
    ]},[_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("Nombre de logements conventionnés APL")))]),_c('div',{staticClass:"input-field_area"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tabFiche.precarityControl.coupleNumberInSocialHousing.$model),expression:"tabFiche.precarityControl.coupleNumberInSocialHousing.$model"}],staticClass:"small-input",attrs:{"type":"number","disabled":_vm.isVIEWER,"id":"coupleNumberInSocialHousing"},domProps:{"value":(_vm.tabFiche.precarityControl.coupleNumberInSocialHousing.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tabFiche.precarityControl.coupleNumberInSocialHousing, "$model", $event.target.value)}}}),(
          _vm.tabFiche.precarityControl.coupleNumberInSocialHousing.$anyDirty &&
            _vm.tabFiche.precarityControl.coupleNumberInSocialHousing.$invalid
        )?_c('div',{staticClass:"validation-field-holder"},[(
            !_vm.tabFiche.precarityControl.coupleNumberInSocialHousing.required
          )?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.tabFiche.precarityControl.precarity.$model === 'CASE_3')?_c('div',{staticClass:"giga-input_field col-lg-6",class:[
      _vm.tabFiche.precarityControl.couplePercentagePrecarity.$anyDirty &&
      _vm.tabFiche.precarityControl.couplePercentagePrecarity.$invalid
        ? 'field-hasError'
        : ''
    ]},[_c('label',{staticClass:"input-field_label",attrs:{"for":"couplePercentagePrecarity"}},[_vm._v(_vm._s(_vm.$t("Pourcentage de ménages en situation de précarité")))]),_c('div',{staticClass:"input-field_area"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tabFiche.precarityControl.couplePercentagePrecarity.$model),expression:"tabFiche.precarityControl.couplePercentagePrecarity.$model"}],staticClass:"small-input",attrs:{"type":"number","disabled":_vm.isVIEWER,"id":"couplePercentagePrecarity"},domProps:{"value":(_vm.tabFiche.precarityControl.couplePercentagePrecarity.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tabFiche.precarityControl.couplePercentagePrecarity, "$model", $event.target.value)}}}),(
          _vm.tabFiche.precarityControl.couplePercentagePrecarity.$anyDirty &&
            _vm.tabFiche.precarityControl.couplePercentagePrecarity.$invalid
        )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.tabFiche.precarityControl.couplePercentagePrecarity.required)?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.tabFiche.precarityControl.precarity.$model === 'CASE_3')?_c('div',{staticClass:"giga-input_field col-lg-6",class:[
      _vm.tabFiche.precarityControl.couplePercentageBigPrecarity.$anyDirty &&
      _vm.tabFiche.precarityControl.couplePercentageBigPrecarity.$invalid
        ? 'field-hasError'
        : ''
    ]},[_c('label',{staticClass:"input-field_label",attrs:{"for":"couplePercentagePrecarity"}},[_vm._v(_vm._s(_vm.$t("Pourcentage de ménages en situation de grande précarité")))]),_c('div',{staticClass:"input-field_area"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
          _vm.tabFiche.precarityControl.couplePercentageBigPrecarity.$model
        ),expression:"\n          tabFiche.precarityControl.couplePercentageBigPrecarity.$model\n        "}],staticClass:"small-input",attrs:{"type":"number","disabled":_vm.isVIEWER,"id":"couplePercentageBigPrecarity"},domProps:{"value":(
          _vm.tabFiche.precarityControl.couplePercentageBigPrecarity.$model
        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tabFiche.precarityControl.couplePercentageBigPrecarity, "$model", $event.target.value)}}}),(
          _vm.tabFiche.precarityControl.couplePercentageBigPrecarity.$anyDirty &&
            _vm.tabFiche.precarityControl.couplePercentageBigPrecarity.$invalid
        )?_c('div',{staticClass:"validation-field-holder"},[(
            !_vm.tabFiche.precarityControl.couplePercentageBigPrecarity.required
          )?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]):_vm._e(),(
      _vm.tabFiche.precarityControl.precarity.$model === 'CASE_2_BIS' ||
        _vm.tabFiche.precarityControl.precarity.$model === 'CASE_4'
    )?_c('div',{staticClass:"giga-input_field col-lg-6",class:[
      _vm.tabFiche.precarityControl.coupleNumberPrecarity.$anyDirty &&
      _vm.tabFiche.precarityControl.coupleNumberPrecarity.$invalid
        ? 'field-hasError'
        : ''
    ]},[_c('label',{staticClass:"input-field_label",attrs:{"for":"coupleNumberPrecarity"}},[_vm._v(_vm._s(_vm.$t("Nombre de ménages en situation de précarité")))]),_c('div',{staticClass:"input-field_area"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tabFiche.precarityControl.coupleNumberPrecarity.$model),expression:"tabFiche.precarityControl.coupleNumberPrecarity.$model"}],staticClass:"small-input",attrs:{"type":"number","disabled":_vm.isVIEWER,"id":"coupleNumberPrecarity"},domProps:{"value":(_vm.tabFiche.precarityControl.coupleNumberPrecarity.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tabFiche.precarityControl.coupleNumberPrecarity, "$model", $event.target.value)}}}),(
          _vm.tabFiche.precarityControl.coupleNumberPrecarity.$anyDirty &&
            _vm.tabFiche.precarityControl.coupleNumberPrecarity.$invalid
        )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.tabFiche.precarityControl.coupleNumberPrecarity.required)?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]):_vm._e(),(
      _vm.tabFiche.precarityControl.precarity.$model === 'CASE_2_BIS' ||
        _vm.tabFiche.precarityControl.precarity.$model === 'CASE_4'
    )?_c('div',{staticClass:"giga-input_field col-lg-6",class:[
      _vm.tabFiche.precarityControl.coupleNumberBigPrecarity.$anyDirty &&
      _vm.tabFiche.precarityControl.coupleNumberBigPrecarity.$invalid
        ? 'field-hasError'
        : ''
    ]},[_c('label',{staticClass:"input-field_label",attrs:{"for":"coupleNumberPrecarity"}},[_vm._v(_vm._s(_vm.$t("Nombre de ménages en situation de grande précarité")))]),_c('div',{staticClass:"input-field_area"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tabFiche.precarityControl.coupleNumberBigPrecarity.$model),expression:"tabFiche.precarityControl.coupleNumberBigPrecarity.$model"}],staticClass:"small-input",attrs:{"type":"number","disabled":_vm.isVIEWER,"id":"coupleNumberBigPrecarity"},domProps:{"value":(_vm.tabFiche.precarityControl.coupleNumberBigPrecarity.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tabFiche.precarityControl.coupleNumberBigPrecarity, "$model", $event.target.value)}}}),(
          _vm.tabFiche.precarityControl.coupleNumberBigPrecarity.$anyDirty &&
            _vm.tabFiche.precarityControl.coupleNumberBigPrecarity.$invalid
        )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.tabFiche.precarityControl.coupleNumberBigPrecarity.required)?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]):_vm._e(),(
      _vm.tabFiche.precarityControl.precarity.$model === 'CASE_2' ||
        _vm.tabFiche.precarityControl.precarity.$model === 'CASE_2_BIS'
    )?_c('div',{staticClass:"giga-input_field col-lg-6",class:[
      _vm.tabFiche.precarityControl.periodId.$anyDirty &&
      _vm.tabFiche.precarityControl.periodId.$invalid
        ? 'field-hasError'
        : ''
    ]},[_c('label',{staticClass:"input-field_label",attrs:{"for":"periodId"}},[_vm._v(_vm._s(_vm.$t("Période")))]),_c('div',{staticClass:"input-field_area"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tabFiche.precarityControl.periodId.$model),expression:"tabFiche.precarityControl.periodId.$model"}],staticClass:"small-input",attrs:{"id":"periodId","disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tabFiche.precarityControl.periodId, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.periods),function(period){return _c('option',{key:period.id,domProps:{"value":period.id}},[_vm._v(" "+_vm._s(period.name)+" ")])}),0),(
          _vm.tabFiche.precarityControl.periodId.$anyDirty &&
            _vm.tabFiche.precarityControl.periodId.$invalid
        )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.tabFiche.precarityControl.periodId.required)?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]):_vm._e(),(
      _vm.tabFiche.precarityControl.precarity.$model === 'CASE_2' ||
        _vm.tabFiche.precarityControl.precarity.$model === 'CASE_2_BIS'
    )?_c('div',{staticClass:"giga-input_field col-lg-12",class:[
      _vm.tabFiche.precarityControl.department.$anyDirty &&
      _vm.tabFiche.precarityControl.department.$invalid
        ? 'field-hasError'
        : ''
    ]},[_c('label',{staticClass:"input-field_label",attrs:{"for":"periodId"}},[_vm._v(_vm._s(_vm.$t("Département")))]),_c('div',{staticClass:"input-field_area"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tabFiche.precarityControl.department.$model),expression:"tabFiche.precarityControl.department.$model"}],staticClass:"small-input",attrs:{"id":"department","disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tabFiche.precarityControl.department, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.departments),function(department){return _c('option',{key:department.code,domProps:{"value":department.code}},[_vm._v(" "+_vm._s(department.name + " (" + department.code + ")")+" ")])}),0),(
          _vm.tabFiche.precarityControl.department.$anyDirty &&
            _vm.tabFiche.precarityControl.department.$invalid
        )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.tabFiche.precarityControl.department.required)?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]):_vm._e(),(
      _vm.tabFiche.precarityControl.precarity.$model === 'CASE_2' ||
        _vm.tabFiche.precarityControl.precarity.$model === 'CASE_2_BIS'
    )?_c('div',{staticClass:"giga-input_field col-lg-6",class:[
      _vm.tabFiche.precarityControl.department.$anyDirty &&
      _vm.tabFiche.precarityControl.department.$invalid
        ? 'field-hasError'
        : ''
    ]},[_c('label',{staticClass:"input-field_label",attrs:{"for":"couplePercentagePrecarityByDepartment"}},[_vm._v(_vm._s(_vm.$t("Pourcentage de ménages en situation de précarité par département")))]),_c('div',{staticClass:"input-field_area"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
          _vm.tabFiche.precarityControl.couplePercentagePrecarityByDepartment
            .$model
        ),expression:"\n          tabFiche.precarityControl.couplePercentagePrecarityByDepartment\n            .$model\n        "}],staticClass:"small-input",attrs:{"type":"number","disabled":_vm.isVIEWER,"id":"couplePercentagePrecarityByDepartment"},domProps:{"value":(
          _vm.tabFiche.precarityControl.couplePercentagePrecarityByDepartment
            .$model
        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tabFiche.precarityControl.couplePercentagePrecarityByDepartment
            , "$model", $event.target.value)}}}),(
          _vm.tabFiche.precarityControl.department.$anyDirty &&
            _vm.tabFiche.precarityControl.department.$invalid
        )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.tabFiche.precarityControl.department.required)?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]):_vm._e(),(
      _vm.tabFiche.precarityControl.precarity.$model === 'CASE_2' ||
        _vm.tabFiche.precarityControl.precarity.$model === 'CASE_2_BIS'
    )?_c('div',{staticClass:"giga-input_field col-lg-6",class:[
      _vm.tabFiche.precarityControl.couplePercentageBigPrecarityByDepartment
        .$anyDirty &&
      _vm.tabFiche.precarityControl.couplePercentageBigPrecarityByDepartment
        .$invalid
        ? 'field-hasError'
        : ''
    ]},[_c('label',{staticClass:"input-field_label",attrs:{"for":"couplePercentagePrecarityByDepartment"}},[_vm._v(_vm._s(_vm.$t( "Pourcentage de ménages en situation de grande précarité par département" )))]),_c('div',{staticClass:"input-field_area"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
          _vm.tabFiche.precarityControl.couplePercentageBigPrecarityByDepartment
            .$model
        ),expression:"\n          tabFiche.precarityControl.couplePercentageBigPrecarityByDepartment\n            .$model\n        "}],staticClass:"small-input",attrs:{"type":"number","disabled":_vm.isVIEWER,"id":"couplePercentageBigPrecarityByDepartment"},domProps:{"value":(
          _vm.tabFiche.precarityControl.couplePercentageBigPrecarityByDepartment
            .$model
        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tabFiche.precarityControl.couplePercentageBigPrecarityByDepartment
            , "$model", $event.target.value)}}}),(
          _vm.tabFiche.precarityControl.couplePercentageBigPrecarityByDepartment
            .$anyDirty &&
            _vm.tabFiche.precarityControl.couplePercentageBigPrecarityByDepartment
              .$invalid
        )?_c('div',{staticClass:"validation-field-holder"},[(
            !_vm.tabFiche.precarityControl
              .couplePercentageBigPrecarityByDepartment.required
          )?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }